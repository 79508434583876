.splide__track .splide__sr {
    display: none;
}

.splide__arrows {
    position: absolute;
    top: calc(100% + 8px);
    left: 16px;
    display: flex;
    grid-gap: 16px;
}

.splide__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: none;
    padding: 0;
}

.splide__arrow svg {
    max-width: 0.75em;
}

.splide__arrow--prev svg {
    transform: scale3d(-1, 1, 1);
}

@media (max-width: 768px) {
    .splide__arrows {
        position: absolute;
        bottom: 0;
        right: 1em;
        display: flex;
        grid-gap: 12px;
    }
}

/* Sidebar */
.sidebar-dropdown .sidebar-dropdown-children {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease;
}

.sidebar-list .disabled {
    display: none;
}

.sidebar-dropdown:not(.has-children) {
    display: none;
}

/* Hide Scrollbar */

.portal-content-area::-webkit-scrollbar,
.portal-sidebar::-webkit-scrollbar {
    width: 0px;
}

.portal-content-area::-webkit-scrollbar-track,
.portal-sidebar::-webkit-scrollbar-track {
    background: #fff;
}

.portal-content-area::-webkit-scrollbar-thumb,
.portal-sidebar::-webkit-scrollbar-thumb {
    background: #888;
}

.portal-content-area::-webkit-scrollbar-thumb:hover,
.portal-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Error */
.color.invalid {
    border-color: var(--error, #d65b5b);
}